import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { makeStyles } from '@material-ui/core/styles';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';


const useStyles = makeStyles((theme) => ({
  root: {
    margin: "10vw",
    textAlign: "center",
  },
}));

const NotFoundPage = (props) => {
  const breakpoints = useBreakpoint();
  const classes = useStyles();
  return (

    <Layout location={props.location} breakpoints = {breakpoints}>
    <Seo title="ページが見つかりません" />
    <div className = {classes.root} >
      <h1>ページが見つかりません</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Layout>
  )
}


export default NotFoundPage
